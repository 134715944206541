<!-- .block-products-carousel -->
<div class="block block-products-carousel" [ngClass]="{
        'block-products-carousel--loading': loading,
        'block-products-carousel--has-items': !!columns?.length
    }" [attr.data-layout]="layout">
    <div [ngClass]="{'container': !withSidebar}" *ngIf="showCarousel">


        <app-block-header style="margin-bottom: 14px;" [header]="header" [groups]="groups" [arrows]="true"
            (next)="carousel.next()" (prev)="carousel.prev()" [showgroups]="false"
            (groupChange)="groupChange.emit($event)">
        </app-block-header>

        <div class="block-products-carousel__slider" #container>
            <!-- <div class="block-products-carousel__preloader"></div>-->

            <owl-carousel-o [options]="carouselOpt" appOwlPreventClick #carousel [appFakeSlides]="columns.length"
                #fakeSlides="appFakeSlides">

                <ng-template *ngFor="let column of columns" carouselSlide>

                    <div class="block-products-carousel__column">
                        <div *ngFor="let product of column" class="block-products-carousel__cell">

                            <app-product-card [product]="product" [showWishList]="false"></app-product-card>
                        </div>
                    </div>

                </ng-template>

                <ng-template *ngFor="let i of fakeSlides.slides" carouselSlide [id]="'fake-slide-'+i">

                </ng-template>

            </owl-carousel-o>

        </div>

    </div>

</div>




<!-- .block-products-carousel / end -->
