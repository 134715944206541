import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlockHeaderGroup } from '../../../../shared/interfaces/block-header-group';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-block-header',
    standalone: true,
    imports: [IconComponent, CommonModule],
    templateUrl: './block-header.component.html',
    styleUrls: ['./block-header.component.scss']
})
export class BlockHeaderComponent {
    @Input() header: string;
    @Input() arrows = false;
    @Input() groups: BlockHeaderGroup[] = [];
    @Input() showgroups = false;

    @Output() next: EventEmitter<any> = new EventEmitter();
    @Output() prev: EventEmitter<any> = new EventEmitter();

    @Output() groupChange: EventEmitter<BlockHeaderGroup> = new EventEmitter();

    constructor() {}

    ngAfterViewInit(): void {
        //   this.currentCard = this.activeslidedata;
    }

    setGroup(group: BlockHeaderGroup): void {
        this.groups.forEach((g) => (g.current = g === group));
        this.groupChange.emit(group);
    }

    onBack(): void {
        this.prev.emit();
    }

    onNext(): void {
        this.next.emit();
    }
}
